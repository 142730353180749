<template lang="html">
  <div :key="dragSelectKey" class="ui stackable grid">
    <div class="ui sixteen wide row form">
      <div class="ten wide column">
        <div class="field">
          <div class="ui sixteen wide right icon input fluid">
            <i
              v-if="query.length < 1"
              class="inverted circular search link icon"
              @click.stop.prevent="loadPage(1)"
            />
            <i
              v-else
              class="close link circle icon"
              @click="clearSearchInput"
            />

            <input
              v-model="query"
              type="text"
              placeholder="Search captions, hashtags, sponsors, etc."
              @keypress.enter.stop.prevent="loadPage(1)"
              @keyup.esc.stop.prevent="clearSearchInput"
            />
          </div>
        </div>

        <div class="inline field">
          <div class="ui input tiny">
            <input
              type="date"
              v-model="startDate"
              @keypress.enter.stop.prevent="loadPage(1)"
            />
          </div>
          until
          <div class="ui input tiny margin-left-small">
            <input
              type="date"
              v-model="endDate"
              @keypress.enter.stop.prevent="loadPage(1)"
            />
          </div>
          <i
            class="ui icon times pointer"
            v-if="startDate || endDate"
            @click="resetDateRange"
          ></i>
        </div>

        <div v-if="!provider" class="inline fields">
          <div class="ui field" v-for="(provider, type) in providers">
            <div
              class="ui checkbox input pointer margin-right-mini"
              :data-for="provider.class"
              :class="{ grey: !providerSelected(type) }"
            >
              <input
                :id="provider.class"
                v-model="selectedProviders"
                type="checkbox"
                :value="type"
              />
              <label :title="provider.label" class="pointer" :for="type"
                ><i
                  :class="`${provider.class} ${
                    providerSelected(type) && 'colored'
                  } icon fitted pointer`"
                />
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="six wide column right aligned">
        <p class="clearing">
          <a v-if="showHideEnabled" class="ui button" @click="hideSearch"
            >Cancel</a
          >
          <a
            v-if="endpoint"
            :class="{ disabled: selectedDeliverables.length < 1, loading }"
            class="ui button primary right floated"
            @click="addSelectedDeliverables"
          >
            Add Selected
            <span v-if="selectedDeliverables.length > 0"
              >({{ selectedDeliverables.length }})</span
            >
          </a>
        </p>

        <span v-if="isStoriesSearch">
          <span v-if="deliverables.length > 0">
            <i
              class="icon th link"
              :class="{ pink: cardGridCount === 8 }"
              @click.stop="toggleGridCount"
            />
            <i
              class="icon th large link"
              :class="{ pink: cardGridCount === 4 }"
              @click.stop="toggleGridCount"
            />
          </span>

          <span
            :data-tooltip="refreshStoriesStatus || 'Check for new stories'"
            data-inverted="true"
            data-variation="mini"
          >
            <i
              class="icon sync link"
              :class="{ pink: refreshingStories, loading: refreshingStories }"
              @click="refreshInstagramStories"
            />
          </span>
        </span>
        <span v-else>
          <span
            :data-tooltip="
              refreshDeliverablesStatus || 'Check for new deliverables'
            "
            data-inverted="true"
            data-variation="mini"
          >
            <i
              class="icon sync link"
              :class="{ loading: refreshingDeliverables }"
              @click="refreshDeliverables"
            />
          </span>
        </span>
      </div>
    </div>

    <div
      v-if="errors || (!loading && (!deliverables || deliverables.length < 1))"
      class="ui sixteen wide column"
    >
      <div v-if="errors" class="ui error message" v-html="errors" />
      <div class="ui message fluid">
        <div v-if="query || startDate || endDate">
          <p>We can't find anything matching your query.</p>
        </div>
        <div v-else>
          <div v-if="noDeliverablesMessages" v-html="noDeliverablesMessages" />
          <div v-else>
            <p>We can't find anything for your account.</p>

            <p v-if="provider">
              In order to add deliverables from
              <span class="capitalize">{{ provider.replace("_", " ") }}</span>
              you must
              <a href="/tokens"> connect your account. </a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="ui sixteen wide column" :class="dragSelectContainerClassObject">
      <div v-if="loading" class="ui active inverted dimmer">
        <div class="ui text loader">Loading ...</div>
      </div>
      <div
        v-if="
          !loading &&
          !noDeliverablesMessages &&
          deliverables &&
          deliverables.length < 1 &&
          tokens &&
          tokens.length < 1
        "
        class="ui message fluid"
        style="z-index: 99999999; position: relative"
      >
        No Facebook accounts connected.
        <a href="/tokens" target="_blank">Connect your account here.</a>
      </div>

      <div :class="`searchResultsSelection-${dragSelectKey}`" />
      <div
        v-show="deliverables && deliverables.length > 0"
        ref="searchResults"
        v-selectable="{ selectedGetter, selectedSetter, selectingSetter }"
        :data-box="`.searchResultsSelection-${dragSelectKey}`"
        :data-constraint="`.dragSelectConstraint-${dragSelectKey}`"
        class="deliverables ui cards doubling"
        :class="cardGridClassObject"
      >
        <template v-for="(deliverable, i) in deliverables">
          <search-result
            v-if="deliverable.provider !== 'instagram_stories'"
            :key="deliverable.id"
            :deliverable="deliverable"
            :selectable="true"
            :class="{
              selected: !!selected[i],
              'pink raised selecting': !!selecting[i],
              added: hasBeenAdded(deliverable),
            }"
            class="selectable"
          />

          <instagram-story
            v-if="deliverable.inferred_type === 'instagram_stories'"
            :key="deliverable.id"
            :deliverable="deliverable"
            :selectable="true"
            :class="{
              selected: !!selected[i],
              'pink raised selecting': !!selecting[i],
              added: hasBeenAdded(deliverable),
            }"
            class="selectable"
            @select="selectDeliverable(deliverable)"
            @deselect="deselectDeliverable(deliverable)"
          />
        </template>
        <div
          class="ui card"
          style="z-index: 99999999; position: relative"
          v-if="deliverables.length > 0 && deliverables.length < perPage"
        >
          <div class="content padding-xy-tiny">
            <div class="description">No older content was found.</div>
          </div>
          <div class="extra content padding-xy-tiny">
            <div class="description">
              <span class="tokensPopup">
                <i class="link icon question circle fitted"></i>
                Help
              </span>
              <div class="ui fluid very wide popup">
                <div style="width: 400px">
                  Content posted before accounts were connected must be added
                  manually.
                  <div
                    v-if="tokens && tokens.length > 0"
                    class="ui divider"
                  ></div>
                  <div v-for="token in tokens">
                    <i class="ui icon colored" :class="token.provider_icon"></i>
                    {{ token.name }}
                    connected: {{ token.created_at | formatDate }}
                  </div>
                  <div
                    v-if="tokens && tokens.length > 0"
                    class="ui divider"
                  ></div>
                  <a
                    href="http://help.influencekit.com/en/collections/4068278-frequently-asked-questions"
                    target="_blank"
                  >
                    <i class="link icon info circle fitted"></i>
                    Learn how to add content manually
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="sixteen wide column">
      <paginate
        v-if="totalResults > 0"
        :key="currentPage"
        :initial-page="currentPage"
        :per-page="perPage"
        :total-results="totalResults"
        show-jump-to="true"
        @click="loadPage"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue/dist/vue.esm";
import Toasted from "vue-toasted";
import * as vueSelectable from "vue-selectable";
import Paginate from "components/pagination.vue";
import _ from "lodash";
import InstagramStory from "./components/instagramStory.vue";
import searchResult from "./components/searchResult.vue";
import EventBus from "components/eventbus";

Vue.use(Toasted, {
  position: "bottom-right",
  duration: 2000,
  singleton: true,
});

const $ = window.jQuery;

const selectable = vueSelectable.default;
const { setSelectableItems } = vueSelectable;
const setSelectableOptions = vueSelectable.setOptions;

export default {
  components: {
    Paginate,
    InstagramStory,
    searchResult,
  },
  directives: { selectable },
  props: {
    event: Object,
    initialSelectedDeliverables: Array,
    endpoint: {
      type: String,
      default: "",
    },
    provider: String,
  },
  data() {
    const $this = this;
    const initialGridSize = this.provider === "instagram_stories" ? 8 : 4;

    const availableProviders = {
      facebook: { class: "facebook", label: "Facebook" },
      instagram: { class: "instagram", label: "Instagram" },
      // twitter: { class: "twitter", label: "Twitter" },
      pinterest: { class: "pinterest", label: "Pinterest" },
      tik_tok: { class: "tik_tok", label: "TikTok" },
      // youtube: { class: "youtube", label: "YouTube" },
      threads: { class: "threads", label: "Threads" },
    };

    return {
      dragSelectKey: Date.now(),
      noDeliverablesMessages: null,
      cardGridCount: initialGridSize,
      loading: false,
      selectedDeliverables: [],
      refreshStoriesStatus: null,
      refreshingStories: false,
      selected: [],
      selecting: [],
      currentPage: 1,
      perPage: 16,
      totalResults: 0,
      deliverables: [],
      tokens: [],
      errors: null,
      query: "",
      startDate: null,
      endDate: null,
      bookmark: null,
      selectedProviders: this.provider
        ? [this.provider]
        : Object.keys(availableProviders),
      providers: availableProviders,
      refreshingDeliverables: false,
      refreshDeliverablesStatus: null,
    };
  },
  computed: {
    dragSelectContainerClassObject() {
      let obj = {
        scroll: this.cardGridCount === 4,
      };
      obj[`dragSelectConstraint-${this.dragSelectKey}`] = true;
      return obj;
    },

    isStoriesSearch() {
      return this.provider === "instagram_stories";
    },
    showHideEnabled() {
      return this.isStoriesSearch;
    },
    cardGridClassObject() {
      return {
        eight: this.cardGridCount === 8,
        four: this.cardGridCount === 4,
      };
    },
    selectedDeliverableIds() {
      return _.map(this.selectedDeliverables.map((d) => d.id));
    },
    initialSelectedDeliverableIds() {
      return _.map(this.initialSelectedDeliverables.map((d) => d.id));
    },
    queryObject() {
      let obj = {
        inferred_type_eq: this.provider,
        event_id_null: true,
        s: "provider_published_at desc",
        deliverable_search_query: this.query,
      };

      if (this.startDate) {
        obj.provider_published_at_dategteq = this.startDate;
      }

      if (this.endDate) {
        obj.provider_published_at_datelteq = this.endDate;
      }

      return obj;
    },
  },
  watch: {
    query(val) {
      if (val === "") {
        this.loadDeliverables();
      }
    },
    selectedProviders(val, oldVal) {
      if (val && val.length > 0 && val !== oldVal) {
        this.loadDeliverables();
      }
    },
    selectedDeliverables(val) {
      if (val.length > 0) {
        EventBus.$emit("eventModalDirty", "deliverableSearch");
      } else {
        EventBus.$emit("eventModalClean", "deliverableSearch");
      }
    },
  },
  mounted() {
    this.loadDeliverables();
    setSelectableOptions(this.$refs.searchResults, { overrideAddMode: true });
  },
  updated() {
    $(".tokensPopup").popup({
      context: this.$el,
      hoverable: true,
      inline: true,
    });
  },
  methods: {
    resetDateRange() {
      this.startDate = null;
      this.endDate = null;
    },
    providerSelected(provider) {
      return _.includes(this.selectedProviders, provider);
    },

    toggleGridCount(e) {
      if (this.cardGridCount === 8) {
        this.cardGridCount = 4;
      } else {
        this.cardGridCount = 8;
      }
      e.stopPropagation();
      return false;
    },
    selectedGetter() {
      return this.selected;
    },
    selectedSetter(v) {
      v.forEach((selected, i) => {
        if (selected) {
          this.selectDeliverable(this.deliverables[i]);
        } else {
          this.deselectDeliverable(this.deliverables[i]);
        }
      });
      this.selected = v;
    },
    selectingSetter(v) {
      this.selecting = v;
    },
    addSelectedDeliverables() {
      const $this = this;
      $this.loading = true;

      if ($this.isStoriesSearch) {
        $this.addInstagramStories();
      } else {
        this.$emit(
          "saveMultiple",
          _.map($this.selectedDeliverables, "url").join("\n")
        );
        Vue.toasted.success(
          `Deliverables added (${$this.selectedDeliverables.length})`
        );
        $this.saveComplete();
        this.hideSearch();
        window.$("a.item[data-tab=multiple]").click();
      }
    },
    addInstagramStories() {
      const $this = this;
      $.ajax({
        url: `${$this.endpoint}/clone_multiple_to_event`,
        type: "patch",
        dataType: "json",
        data: {
          deliverable_ids: $this.selectedDeliverableIds,
        },
        success: [this.saveSuccess],
        error: this.saveError,
        complete: this.saveComplete,
      });
    },
    saveComplete() {
      this.loading = false;
      EventBus.$emit("eventModalClean", "deliverableSearch");
    },
    saveSuccess(data) {
      this.deselectAll();
      data.forEach((deliverable) => {
        if (deliverable.id) {
          this.$emit("save", deliverable);
        }
      });

      this.hideSearch();
    },
    saveError(data) {
      this.loading = false;
      if (data.responseJSON) {
        this.errors = `Error: ${data.responseJSON}`;
      } else {
        this.errors = `There was an error saving your data.`;
      }
    },
    deselectAll() {
      this.selectedDeliverables = [];
      this.selected = [];
      this.deliverables.forEach((d) => {
        d.selected = false;
      });
    },
    hideSearch() {
      this.clearSearchInput();
      this.deselectAll();
      this.$emit("hide");
    },
    clearSearchInput() {
      this.query = "";
    },
    loadPage(page) {
      this.currentPage = page;
      this.loadDeliverables();
    },
    refreshInstagramStories() {
      this.refreshingStories = true;
      $.get(
        `/account/refresh_instagram_stories?user_id=${
          this.event ? this.event.user_id : ""
        }`
      ).done((data) => {
        this.refreshStoriesStatus = data.message;
      });
    },

    refreshDeliverables() {
      this.refreshingDeliverables = true;
      $.get(
        `/account/refresh_deliverables_index?user_id=${
          this.event ? this.event.user_id : ""
        }`
      ).done((data) => {
        this.refreshDeliverablesStatus = data.message;
      });
    },

    deliverableIsSelected(deliverable, d) {
      if (!d.url || !deliverable.url) {
        return false;
      }
      if (d.url.match(/facebook\.com/)) {
        const pattern = /.*facebook\.com\/.*?\/(\d+)(?:\/|\?|$)/;

        const id1 = d.url.match(pattern);
        const id2 = deliverable.url.match(pattern);

        const matches = _.intersection(id1, id2).length > 0;

        return matches;
      } else {
        return d.url === deliverable.url;
      }
    },
    selectDeliverable(deliverable) {
      deliverable.selected = true;
      this.$set(
        this,
        "selectedDeliverables",
        _.union(this.selectedDeliverables, [deliverable])
      );
    },
    deselectDeliverable(deliverable) {
      const index = _.findIndex(
        this.selectedDeliverables,
        (d) => d.id === deliverable.id
      );
      this.$set(deliverable, "selected", false);
      this.$delete(this.selectedDeliverables, index);
    },
    loadDeliverables() {
      this.loading = true;
      let params = {
        q: this.queryObject,
        page: this.currentPage,
        per: this.perPage,
        bookmark: this.bookmark,
        providers: this.selectedProviders,
      };

      let searchEndpoint = "/deliverables/search.json";

      if (this.endpoint.includes("/tenant_assignments/")) {
        let tenant_assignment_id = this.endpoint.match(
          /\/tenant_assignments\/(\d+)\//
        )[1];
        params.tenant_assignment_id = tenant_assignment_id;
      }
      $.get(searchEndpoint, params)
        .done(this.loadSuccess)
        .fail(this.loadError)
        .always(this.loadComplete);
    },
    loadSuccess(data, status, xhr) {
      this.noDeliverablesMessages = data.message;
      this.deliverables = data.results;
      this.tokens = data.tokens;
      this.bookmark = data.bookmark;
      this.totalResults = parseInt(xhr.getResponseHeader("total"), 10);
    },
    loadError(data) {
      this.errors = data.errors;
    },
    loadComplete() {
      this.$nextTick(() => setSelectableItems(this.$refs.searchResults));
      this.loading = false;
    },
    hasBeenAdded(deliverable) {
      return (
        _.intersectionWith(
          this.initialSelectedDeliverables,
          [deliverable],
          this.deliverableIsSelected
        ).length > 0
      );
      // return (
      //   _.intersectionBy(this.initialSelectedDeliverables, [deliverable], "url")
      //     .length > 0
      // );
    },
  },
};
</script>

<style lang="scss" scoped>
.sixteen.wide.column {
  &.scroll {
    overflow: hidden;
    overflow-y: auto;
    max-height: 500px;
  }
}

i.th,
i.sync {
  font-size: 1.5em !important;
  vertical-align: baseline !important;
}

.deliverables.ui.cards {
  margin-left: -2.5em;
  padding-left: 2em;
  margin-right: -2.5em;
  padding-right: 2em;
  margin-top: -2.5em;
  padding-top: 2em;
}

*[class*="searchResultsSelection"] {
  position: absolute;
  border: 1px dashed #777;
  border-radius: 0.28571429rem;
  z-index: 9999;
  top: 0;
  left: 0;
  cursor: default;
  display: none;
}
</style>
